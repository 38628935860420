<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-relation-many-to-many"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Conciliações</div>
      </template>
      <v-btn
        v-if="accessReleased('CONCILIACOES_ADICIONAR')"
        id="btn-nova-conciliacao"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push(`edit`)"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Nova Conciliação
      </v-btn>
      <v-text-field
        id="conciliacoes-historico-search"
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />

      <general-progress-bars
        class="mt-11"
        :items="downloadProgressData"
        prefixText="Baixando Conciliação"
      />

      <v-divider class="mt-10" />

      <v-alert
        dense
        outlined
        type="info"
        color="blue"
        class="mb-0 py-1 px-1"
        v-if="usuarioAdmin"
      >
        Algumas conciliações são criadas automaticamente pelo sistema após
        importações de BDGD.
        <router-link to="/conciliacoes-automaticas/historico"
          >Clique aqui</router-link
        >
        para visualizar os processos de conciliações automáticas que estão na
        fila.
      </v-alert>

      <v-divider class="mt-10" />

      <v-data-table
        :headers="headers"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhuma conciliação disponível"
      >
        <template v-slot:[`item.id`]="{ item }">{{ item.id }}</template>
        <template v-slot:[`item.nome`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="text-truncate-column"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.nome }}
              </div>
            </template>
            <span>{{ item.nome }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.data_processamento`]="{ item }">
          {{ item.data_processamento | parseToDateTimeBR }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <general-status :status="item.status" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :class="item.status != 'SUCESSO' ? 'cursor-block' : ''"
            id="action-download-conciliacao"
            class="px-1"
            min-width="0"
            fab
            icon
            x-small
            @click="downloadItem(item)"
            :loading="
              downloadProgressData[item.id] !== undefined ? true : false
            "
            :disabled="item.status != 'SUCESSO'"
          >
            <v-icon small> mdi-download </v-icon>
          </v-btn>
          <v-btn
            v-if="
              accessReleased('CONCILIACOES_EDITAR_EXPRESS') ||
              accessReleased('CONCILIACOES_EDITAR_CUSTOM')
            "
            :class="!canEditItem(item) ? 'cursor-block' : ''"
            id="action-edit-conciliacao"
            class="px-1 ml-n1"
            min-width="0"
            fab
            icon
            x-small
            @click="
              $router.replace(
                `edit/${item.tipo_conciliacao.toLowerCase()}/${item.id}`
              )
            "
            :disabled="!canEditItem(item)"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more-conciliacao"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="canRunItem(item)"
                @click="executarItem(item.id)"
              >
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small> mdi-play </v-icon>
                </v-list-item-icon>
                <v-list-item-title> Executar </v-list-item-title>
              </v-list-item>
              <v-list-item @click="duplicarItem(item.id)">
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small>mdi-content-copy</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Duplicar </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="canDeleteItem(item)"
                @click="openDialogDelete(item.id, item.nome)"
              >
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Deletar </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openDialogDetalhes(item)">
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small> mdi-file-find-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-title> Detalhes </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  (dialogCreationLog = true),
                    (dialogCreationLogData = {
                      username: item.usuario,
                      created: item.criado_em
                    })
                "
              >
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable')"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Clique aqui para recarregar as conciliações</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title"
        >Deletar Conciliação #{{ dialogDeleteData.id }}</template
      >
      <template slot="body"
        >Tem certeza que deseja deletar a conciliação
        <strong>{{ dialogDeleteData.titulo }}</strong
        >?</template
      >
    </dialog-delete>
    <dialog-detalhes
      title="Detalhes da Conciliação"
      :dialog.sync="dialogDetalhes"
    >
      <template slot="body">
        <v-data-table
          class="elevation-1 style-table-dialog-detalhes"
          :headers="dialogDetalhesHeader"
          :items="dialogDetalhesData"
          :mobile-breakpoint="0"
          hide-default-footer
          disable-pagination
        />
      </template>
    </dialog-detalhes>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import ConciliacoesService from '@/services/ConciliacoesService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins';
import downloadSpreadSheetMixins from '@/mixins/downloadSpreadSheetMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [
    powerupsActionsMixins,
    downloadSpreadSheetMixins,
    profilePermissionsMixin
  ],

  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    DialogDelete: () => import('@/components/general/DialogDelete'),
    DialogDetalhes: () => import('@/components/general/DialogDetalhes'),
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
    GeneralProgressBars: () =>
      import('@/components/general/GeneralProgressBars.vue')
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    powerupService: ConciliacoesService,
    powerupEntityName: 'Conciliação',
    search: '',
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Nome',
        value: 'nome'
      },
      {
        text: 'Executado',
        value: 'data_processamento'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ],
    dialogDetalhesHeader: [
      { text: 'Resumo', value: 'parametro', sortable: false },
      { text: 'Valor', value: 'valor', sortable: false }
    ],
    usuarioAdmin: false
  }),

  mounted() {
    this.getUsuarioAdmin();
  },

  methods: {
    openDialogDetalhes(conciliacao) {
      this.dialogDetalhesData = [
        {
          parametro: 'NOME:',
          valor: conciliacao.nome
        },
        {
          parametro: 'TIPO DE CONCILIAÇÃO:',
          valor:
            conciliacao.tipo_conciliacao == 'EXPRESS' ? 'Express' : 'Custom'
        },
        {
          parametro: 'FLEXIBILIDADE:',
          valor: conciliacao.flexibilidade
        },
        {
          parametro: `${conciliacao.origem_partida.tipo} BASE DE PARTIDA:`,
          valor: conciliacao.origem_partida.nome
        },
        {
          parametro: `${conciliacao.origem_referencia.tipo} BASE DE REFERÊNCIA:`,
          valor: conciliacao.origem_referencia.nome
        },
        {
          parametro: 'PRIORIDADES:',
          valor: conciliacao.prioridades.length
            ? conciliacao.prioridades.join(', ')
            : '-'
        },
        {
          parametro: 'RODADAS:',
          valor: conciliacao.numero_rodadas
        },
        {
          parametro: 'HABILITAR ATRIBUTOS CORINGA?:',
          valor: conciliacao.atributo_coringa ? 'Sim' : 'Não'
        },
        {
          parametro: 'TOTAL CONCILIADO:',
          valor:
            conciliacao.status == 'AGUARDANDO'
              ? '-'
              : conciliacao.total_conciliado
        },
        {
          parametro: 'SOBRAS PARTIDA:',
          valor:
            conciliacao.status == 'AGUARDANDO'
              ? '-'
              : conciliacao.total_sobra_contabil
        },
        {
          parametro: 'SOBRAS REFERÊNCIA:',
          valor:
            conciliacao.status == 'AGUARDANDO'
              ? '-'
              : conciliacao.total_sobra_fisica
        }
      ];

      if (conciliacao.tipo_conciliacao == 'EXPRESS') {
        this.dialogDetalhesData.splice(3, 0, {
          parametro: 'TUC SELECIONADA:',
          valor: conciliacao.tuc_selecionada
        });
      }
      this.dialogDetalhes = true;
    },
    async getUsuarioAdmin() {
      this.usuarioAdmin = await AuthService.userIsAdmin();
    }
  }
};
</script>
